import React from 'react';
import { Link } from 'gatsby';

const MobileNav = () => {

    return (
        <div className="mobileNav">
                <Link to='/about-me' activeClassName='activeNavLink'><div>About Me</div></Link>
                <Link to='/contact' activeClassName='activeNavLink'><div>Contact</div></Link>
                <Link to='/socials' activeClassName='activeNavLink'><div>Socials</div></Link>
                <Link to='/experience' activeClassName='activeNavLink'><div>Experience</div></Link>
        </div>
    );
}

export default MobileNav;