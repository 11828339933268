/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/site.scss';

const Layout = ({ children }) => {

  return (
    <>
      <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet"></link>

      <Header />
      <div className="grid-container">
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
