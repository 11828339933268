import { Link } from "gatsby";
import React, {useState} from "react";
import HamburgerMenu from "react-hamburger-menu";
import MobileNav from './mobile-nav';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  function handleHamburgerClick() {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header>
      
      {mobileMenuOpen ? <MobileNav className="mobileNav" /> : null }

      <Link to='/' className='name'>
        Garrett DeMeyer
      </Link>
      
      <ul className="desktopNav">
        <li><Link to='/about-me'>About Me</Link></li>
        <li><Link to='/experience'>Experience</Link></li>
        <li><Link to='/socials'>Socials</Link></li>
        <li><Link to='/contact'>Contact</Link></li>
      </ul>

    <div className="mobileNavButtonContainer">
        <HamburgerMenu
          className="mobileNavButton"
          isOpen={mobileMenuOpen}
          menuClicked={handleHamburgerClick.bind(this)}
          color='#B4BBBF'
        />
      </div>
    </header>
  );
};

export default Header
